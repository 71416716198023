const getters = {
  userName: state => state.app.userName,
  idToken: state => state.app.idToken,
  userInfo: state => state.app.userInfo,
  busId: state => state.app.busId,
  dataMaintainModel: state => state.app.dataMaintainModel,
  // 需要别的双向绑定的数据这里需要继续添加
  vipData: state => state.user.vipData,
  mesCont: state => state.app.mesCont,
  foucsCont: state => state.app.foucsCont,
  customerServiceTel: state => state.app.customerServiceTel
}
export default getters
