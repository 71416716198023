/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/transfer/:id',
    name: 'transfer',
    meta: {
      navColor: false,
      title: '页面访问',
      keepAlive: false,
      requireAuth: false,
      hideInMenu: true
    },
    component: () => import('@/views/transfer/transfer.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login'),
    meta: {
      navColor: false,
      title: '登录',
      keepAlive: false,
      requireAuth: false
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/views/login/forget-password'),
    meta: {
      navColor: false,
      title: '找回密码',
      keepAlive: false,
      requireAuth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/login/register'),
    meta: {
      navColor: false,
      title: '新用户注册',
      keepAlive: false,
      requireAuth: false
    }
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/login/callback'),
    meta: {
      navColor: false,
      title: '扫码登录',
      keepAlive: false,
      requireAuth: false
    }
  },
  {
    path: '/',
    component: () => import('@/views/home/index'),
    redirect: '/index',
    meta: {
      navColor: true,
      title: '首页',
      keepAlive: false
    },
    children: [{
      path: '/test',
      name: 'Test',
      component: () => import('@/views/test/test'),
      meta: {
        navColor: false,
        title: '测试',
        keepAlive: false,
        requireAuth: false
      }
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/views/index/index'),
      meta: {
        navColor: true,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/report/report'),
      meta: {
        navColor: true,
        title: '企业报告',
        keepAlive: false,
        requireAuth: false
      }
    },
    {
      path: '/report-list',
      name: 'report-list',
      component: () => import('@/views/report/report-list'),
      meta: {
        navColor: false,
        title: '企业报告',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查企业
      path: '/company',
      name: 'company',
      component: () => import('@/views/general-query/company'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查资质
      path: '/company-credential',
      name: 'company-credential',
      component: () => import('@/views/general-query/company-credential'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查中标
      path: '/company-achievement',
      name: 'company-achievement',
      component: () => import('@/views/general-query/company-achievement'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 中标数据报告
      path: '/company-achievement-report',
      name: 'company-achievement-report',
      component: () => import('@/views/general-query/company-achievement-report'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查荣誉证书
      path: '/company-honor-image',
      name: 'company-honor-image',
      component: () => import('@/views/general-query/company-honor-image'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查技术人员
      path: '/company-registered-personnel',
      name: 'company-registered-personnel',
      component: () => import('@/views/general-query/company-registered-personnel'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查人员业绩
      path: '/company-personnel-perfo',
      name: 'company-personnel-perfo',
      component: () => import('@/views/general-query/company-personnel-perfo'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 查知识产权
      path: '/company-intellectual-property',
      name: 'company-intellectual-property',
      component: () => import('@/views/general-query/company-intellectual-property'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 中标详情
      path: '/company-achievement-details',
      name: 'company-achievement-details',
      component: () => import('@/views/general-query/company-achievement-details'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 商标详情
      path: '/company-tm-details',
      name: 'company-tm-details',
      component: () => import('@/views/general-query/company-tm-details'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 专利详情
      path: '/company-patent-details',
      name: 'company-patent-details',
      component: () => import('@/views/general-query/company-patent-details'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 企业详情
      path: '/enterprise-details',
      name: 'enterprise-details',
      component: () => import('@/views/general-query/enterprise-details'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 招聘信息
      path: '/recruitinfo-mes',
      name: 'recruitinfo-mes',
      component: () => import('@/views/general-query/recruitinfo-mes'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 企业年报
      path: '/report-mes',
      name: 'report-mes',
      component: () => import('@/views/general-query/report-mes'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 法律诉讼
      path: '/law-mes',
      name: 'law-mes',
      component: () => import('@/views/general-query/law-mes'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 企业画像
      path: '/corporate-mes',
      name: 'corporate-mes',
      component: () => import('@/views/general-query/corporate-mes'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 企业高级查询条件
      path: '/high-query-conditions',
      name: 'high-query-conditions',
      component: () => import('@/views/high-query/high-query-conditions'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 企业高级查询结果
      path: '/high-query-lsit',
      name: 'high-query-lsit',
      component: () => import('@/views/high-query/high-query-lsit'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 数据纠错
      path: '/data-correction',
      name: 'data-correction',
      component: () => import('@/views/data-correction/data-correction'),
      meta: {
        navColor: true,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 关于我们
      path: '/about',
      name: 'about',
      component: () => import('@/views/about/about'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 个人中心
      path: '/personal-center',
      name: 'personal-center',
      component: () => import('@/views/personal-center/personal-center'),
      meta: {
        navColor: false,
        title: '个人中心',
        keepAlive: false,
        requireAuth: true
      },
      redirect: '/message-center',
      children: [
        {
          path: '/message-center',
          name: 'message-center',
          component: () => import('@/views/personal-center/message-center'),
          meta: {
            navColor: false,
            title: '消息中心',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/attention-company',
          name: 'attention-company',
          component: () => import('@/views/personal-center/attention-company'),
          meta: {
            navColor: false,
            title: '我的关注',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/data-maintain-person',
          name: 'data-maintain-person',
          component: () => import('@/views/personal-center/data-maintain-person'),
          meta: {
            navColor: false,
            title: '维护记录',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/coupon',
          name: 'coupon',
          component: () => import('@/views/personal-center/coupon'),
          meta: {
            navColor: false,
            title: '优惠券',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/invoice-list',
          name: 'invoice-list',
          component: () => import('@/views/personal-center/invoice-list'),
          meta: {
            navColor: false,
            title: '我的发票',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/invoice',
          name: 'invoice',
          component: () => import('@/views/personal-center/invoice'),
          meta: {
            navColor: false,
            title: '开具发票',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/order',
          name: 'order',
          component: () => import('@/views/personal-center/order'),
          meta: {
            navColor: false,
            title: '我的订单',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/associated-account',
          name: 'associated-account',
          component: () => import('@/views/personal-center/associated-account'),
          meta: {
            navColor: false,
            title: '关联账户',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/account-security',
          name: 'account-security',
          component: () => import('@/views/personal-center/account-security'),
          meta: {
            navColor: false,
            title: '账号安全',
            keepAlive: true,
            requireAuth: true
          }
        }
      ]
    },
    { // 企业认证
      path: '/company-certification',
      name: 'company-certification',
      component: () => import('@/views/company-certification/company-certification'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 数据维护
      path: '/data-maintain',
      name: 'data-maintain',
      component: () => import('@/views/data-maintain/data-maintain'),
      meta: {
        navColor: false,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    },
    { // 开通vip
      path: '/vip-index',
      name: 'vip-index',
      component: () => import('@/views/vip/vip-index'),
      meta: {
        navColor: true,
        title: 'E查工程',
        keepAlive: false,
        requireAuth: false
      }
    }]
  }
]
