const state = {
  userName: 'steady',
  redirectUri: '/index',
  idToken: window.localStorage.getItem('idToken'),
  mesCont: window.localStorage.getItem('mesCont'),
  foucsCont: window.localStorage.getItem('foucsCont'),
  userInfo: window.localStorage.getItem('userInfo'),
  busId: window.localStorage.getItem('busId'), // 业务ID 需要跨路由传id的地方使用 多用传递公司id
  dataMaintainModel: window.localStorage.getItem('dataMaintainModel'), // 数据维护重新提交使用 格式 {key: companyCredential, value: {a:1,b:2}}
  customerServiceTel: window.localStorage.getItem('customerServiceTel') // 客服手机号
}
const mutations = {
  SET_USER_NAME (state, name) {
    state.userName = name
  },
  SET_IDTOKEN (state, idToken) {
    state.idToken = idToken
    window.localStorage.setItem('idToken', idToken)
  },
  SET_MESCONT (state, mesCont) {
    state.mesCont = mesCont
    window.localStorage.setItem('mesCont', mesCont)
  },
  SET_FOUCSCONT (state, foucsCont) {
    state.foucsCont = foucsCont
    window.localStorage.setItem('foucsCont', foucsCont)
  },
  SET_USERINFO (state, userInfo) {
    state.userInfo = userInfo
    window.localStorage.setItem('userInfo', userInfo)
  },
  SET_BUS_ID (state, busId) {
    state.busId = busId
    window.localStorage.setItem('busId', busId)
  },
  SET_DATA_MAINTAIN_MODEL (state, dataMaintainModel) {
    state.dataMaintainModel = dataMaintainModel
    window.localStorage.setItem('dataMaintainModel', dataMaintainModel)
  },
  SET_CUSTOMER_SERVICE_TEL (state, customerServiceTel) {
    state.customerServiceTel = customerServiceTel
    window.localStorage.setItem('customerServiceTel', customerServiceTel)
  },
  LOGOUT (state) {
    state.idToken = null
    state.userInfo = null
    state.vipData = null
    state.mesCont = null
    state.foucsCont = null
    window.localStorage.removeItem('foucsCont')
    window.localStorage.removeItem('idToken')
    window.localStorage.removeItem('userInfo')
    window.localStorage.removeItem('vipData')
    window.localStorage.removeItem('mesCont')
  }
}
const actions = {
  // 设置name
  setUserName ({ commit }, name) {
    commit('SET_USER_NAME', name)
  },
  setIdToken ({ commit }, idToken) {
    commit('SET_IDTOKEN', idToken)
  },
  setMesCont ({ commit }, mesCont) {
    commit('SET_MESCONT', mesCont)
  },
  setFoucsCont ({ commit }, foucsCont) {
    commit('SET_FOUCSCONT', foucsCont)
  },
  setUserInfo ({ commit }, userInfo) {
    commit('SET_USERINFO', userInfo)
  },
  setDataMaintainModel ({ commit }, dataMaintainModel) {
    commit('SET_DATA_MAINTAIN_MODEL', dataMaintainModel)
  },
  setBusId ({ commit }, busId) {
    commit('SET_BUS_ID', busId)
  },
  setCustomerServiceTel ({ commit }, customerServiceTel) {
    commit('SET_CUSTOMER_SERVICE_TEL', customerServiceTel)
  },
  logout ({ commit }) {
    commit('LOGOUT')
  }
}
export default {
  state,
  mutations,
  actions
}
