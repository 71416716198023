import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import VueViewer from 'v-viewer'
import scroll from 'vue-seamless-scroll/src'
import 'element-plus/lib/theme-chalk/index.css'
import 'dayjs/locale/zh-cn' // 国际化
import locale from 'element-plus/lib/locale/lang/zh-cn' // 国际化
// 引入公用css
import './index.css'
import '@/assets/icon.css'
// 引入字体图标库css
import '@/assets/iconfont/iconfont.css'
// 引入动画库
import animated from 'animate.css'
// 图片放大组件
import 'viewerjs/dist/viewer.css'
// 路由守卫
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (store.getters.idToken != null) {
      // 通过封装好的cookies读取token，如果存在，name接下一步如果不存在，那跳转回登录页
      next() // 不要在next里面加"path:/",会陷入死循环
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    if (to.name === 'data-maintain') {
      if (store.getters.idToken === null) {
        next({
          path: '/login'
        })
        return
      }
      let businessInformationStatus = '0'
      const userInfo = store.getters.userInfo ? JSON.parse(store.getters.userInfo) : null
      businessInformationStatus = userInfo?.businessInformationStatus
      if (businessInformationStatus === '0' || !businessInformationStatus) {
        next({
          path: '/company-certification'
        })
        return
      }
    }
    next()
  }
  if (to.meta.title) {
    document.title = to.meta.title
    next()
  }
})

VueViewer.setDefaults({
  zIndexInline: 9999
})
createApp(App).use(ElementPlus, { locale }) // 国际化 引入中文
const app = createApp(App)
app.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
app.use(ElementPlus)
app.use(router)
app.use(VueViewer)
app.use(store)
app.use(animated)
app.use(scroll)
app.mount('#app')
// 公共变量的方法
const globalObj = {
  navColor: true
}
app.config.globalProperties.$global = globalObj
