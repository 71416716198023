const state = {
  vipData: window.localStorage.getItem('vipData')
}

const mutations = {
  SET_VIPDATA: (state, vipData) => {
    state.vipData = vipData
    window.localStorage.setItem('vipData', vipData)
  }
}

const actions = {
  setVipData ({ commit }, name) {
    commit('SET_VIPDATA', name)
  }
}

export default {
  state,
  mutations,
  actions
}
